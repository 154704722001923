/*  imported from icon.less  */

#meepshop { .meepshop-meep-ui__menu-icon__root {
  display: inline-flex;
  align-items: center;
  vertical-align: top;

  &.meepshop-meep-ui__menu-icon__wrap {
    white-space: pre-wrap;
  }

  &.meepshop-meep-ui__menu-icon__LEFT {
    flex-direction: row;

    .meepshop-meep-ui__menu-icon__icon {
      margin: 0px 5px 0px 0px;
    }
  }

  &.meepshop-meep-ui__menu-icon__RIGHT {
    flex-direction: row-reverse;

    .meepshop-meep-ui__menu-icon__icon {
      margin: 0px 0px 0px 5px;
    }
  }

  &.meepshop-meep-ui__menu-icon__UPON {
    flex-direction: column;

    .meepshop-meep-ui__menu-icon__icon {
      margin: 0px 0px 5px 0px;
    }
  }

  &.meepshop-meep-ui__menu-icon__BELOW {
    flex-direction: column-reverse;

    .meepshop-meep-ui__menu-icon__icon {
      margin: 5px 0px 0px 0px;
    }
  }
}

.meepshop-meep-ui__menu-icon__icon {
  font-size: 24px;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";