/*  imported from products.less  */

@import '~@store/utils/styles/variables.less';
#meepshop {
.store-cart-previewer__previewer-products__root {
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: @screen-sm-max) {
    margin-top: -8px;
  }

  tbody {
    vertical-align: top;
  }

  tr {
    > td {
      padding: 10px 0;

      @media (max-width: @screen-sm-max) {
        padding: 8px 0;
        border-bottom: none;
      }
    }

    &:hover > td {
      background-color: transparent;
    }
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";