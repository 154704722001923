/*  imported from empty.less  */

@import '~@store/utils/styles/variables.less';
#meepshop {
.store-cart-previewer__previewer-empty__root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px;

  @media (max-width: @screen-sm-max) {
    flex-grow: 1;
    padding: 16px;
    margin-bottom: 71px;
  }

  > div:first-child {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 6px;

    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;

    @media (max-width: @screen-sm-max) {
      margin-bottom: 24px;
    }
  }
}

.store-cart-previewer__previewer-empty__cart {
  display: none;
  width: 30%;
  height: auto;
  margin: 0 3% 8px 0;

  @media (max-width: @screen-sm-max) {
    display: block;
  }
}

.store-cart-previewer__previewer-empty__button {
  display: none;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;

  @media (max-width: @screen-sm-max) {
    display: block;
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";