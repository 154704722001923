/*  imported from price.less  */

@import '~@store/utils/styles/variables.less';
#meepshop {
.store-cart-previewer__previewer-price__root {
  width: 100%;
  font-size: 12px;
  line-height: 20px;

  @media (max-width: @screen-sm-max) {
    padding-top: 16px;
    border-top-width: 1px;
    border-top-style: solid;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    > div:first-child {
      max-width: 208px;
    }
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";