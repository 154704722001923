/*  imported from menuItem.less  */

@import '../../constants/styles.less';
#meepshop {
.meepshop-meep-ui__menu-menuItem__menuItem {
  font-weight: 400;
  font-size: inherit;
  transition: unset;

  &.menu-1 {
    &:not(.ant-menu-submenu),
    &.ant-menu-submenu-inline,
    & > .ant-menu-submenu-title {
      line-height: 1.5;
      padding: 15px 20px;
      font-size: inherit;
    }

    & > .ant-menu-submenu-title {
      display: flex;
      justify-content: space-between;
    }
  }

  &.menu-2,
  &.menu-3 {
    display: block;
    margin: 0px;
    padding: 10px;
    width: 100%;
    height: inherit;
    line-height: 1.5;

    &.meepshop-meep-ui__menu-menuItem__fontBold {
      font-weight: 700;
    }

    &.ant-menu-submenu-active {
      color: inherit;
    }

    &.ant-menu-submenu.ant-menu-submenu-popup {
      padding: 0px;
    }

    & > .ant-menu-submenu-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px;
      padding: 0px;
      height: inherit;
      line-height: 1.5;

      &:hover {
        color: inherit;
      }
    }
  }

  a {
    color: inherit !important;
    fill: inherit !important;
  }
}

.ant-menu-vertical {
  .meepshop-meep-ui__menu-menuItem__menuItem {
    &.menu-1 {
      margin: 0px;
      height: 100%;

      &:not(.ant-menu-submenu),
      & > .ant-menu-submenu-title {
        display: flex;
        align-items: center;
      }

      & > .ant-menu-submenu-title {
        margin: 0px;
        height: 100%;

        & > .ant-menu-submenu-arrow {
          position: unset;
        }
      }
    }

    &.menu-2,
    &.menu-3 {
      @media @PHONE_MEDIA {
        font-size: 16px;
      }
    }
  }
}

.ant-menu-inline {
  .meepshop-meep-ui__menu-menuItem__menuItem {
    &.menu-1 {
      width: auto;
      height: auto;
      font-size: inherit;
      font-weight: 700;

      &,
      &:not(:last-child) {
        margin: 0px;
      }

      & > *:first-child {
        margin: 10px 0px;
        padding: 0px;
      }

      & > .ant-menu-submenu-title {
        padding: 0px !important;
        width: 100%;
        height: auto;
        white-space: pre-wrap;
      }
    }

    &.menu-2,
    &.menu-3 {
      margin: 0px;
      padding: 3px 0px !important;
      height: auto;
      line-height: 1.5;
      font-size: inherit;

      & > .ant-menu-submenu-title {
        margin: 0px;
        padding: 3px 0px !important;
        height: auto;
        line-height: 1.5;
      }

      & > .ant-menu-sub {
        font-size: inherit;
      }
    }

    &.menu-3 {
      padding: 3px 0px 3px 15px !important;
    }
  }

  .ant-menu-submenu-title {
    font-size: inherit;
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";