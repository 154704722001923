/*  imported from searchBar.less  */

#meepshop { .meepshop-meep-ui__menu-searchBar__form {
  font-size: inherit;
  color: inherit;
}

.meepshop-meep-ui__menu-searchBar__input {
  width: 100px;
  font-size: inherit;
  border: 0px;
  color: inherit;
  background: transparent;

  &:focus {
    box-shadow: 0 0 0 0px;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";