/*  imported from useProductsColumns.less  */

@import '~@store/utils/styles/variables.less';
#meepshop {
div.store-cart-previewer__previewer-useProductsColumns__img {
  border-radius: 2px;
  margin: 0 12px 0 0;
}

.store-cart-previewer__previewer-useProductsColumns__upselling {
  display: inline-block;
  padding: 0 6px;
  font-size: 10px;
  line-height: 18px;
  margin: 4px 0;
}

.store-cart-previewer__previewer-useProductsColumns__title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 2px;

  @media (max-width: @screen-sm-max) {
    margin-bottom: 4px;
  }
}

.store-cart-previewer__previewer-useProductsColumns__offline {
  opacity: 0.4;
}

.store-cart-previewer__previewer-useProductsColumns__specs {
  font-size: 12px;
  margin-bottom: 3px;

  @media (max-width: @screen-sm-max) {
    margin-bottom: 4px;
  }
}

.store-cart-previewer__previewer-useProductsColumns__tags {
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  > span {
    border-radius: 3px;
    display: inline-block;
    padding: 2px 8px 2px 6px;

    &:not(:last-child) {
      margin: 0 3px 3px 0;

      @media (max-width: @screen-sm-max) {
        margin: 0 4px 4px 0;
      }
    }

    > span:first-child {
      margin-right: 5px;
    }
  }
}

.store-cart-previewer__previewer-useProductsColumns__price {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 2px;

  > span:first-child {
    font-size: 12px;
    font-weight: 400;
    margin-right: 4px;
  }
}

.store-cart-previewer__previewer-useProductsColumns__delete {
  font-size: 12px;
  margin-left: 8px;
  opacity: 0.5;

  @media (max-width: @screen-sm-max) {
    font-size: 16px;
  }
}

.store-cart-previewer__previewer-useProductsColumns__error {
  color: #bd373c;
  font-size: 16px;
  font-weight: 400;

  span:first-child {
    font-size: 14px;
    margin-right: 5px;
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";