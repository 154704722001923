/*  imported from cartCount.less  */

#meepshop { .meepshop-meep-ui__menu-cartCount__root {
  display: flex;
  font-size: inherit;

  .ant-badge-count {
    top: calc(100% - 24px);
    z-index: 1;

    .ant-scroll-number-only-unit:not(.current) {
      opacity: 0;
    }
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";